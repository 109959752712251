<template>
  <Dice />
</template>

<script>
import Dice from './components/Dice.vue';

export default {
  name: 'App',
  components: {
    Dice
  }
};
</script>

<style lang="scss">
body {
  background: #ffc5a3;
  margin: 0;
}
</style>
